import { render, staticRenderFns } from "./FeedCard.vue?vue&type=template&id=3c9191fa&"
import script from "./FeedCard.vue?vue&type=script&lang=js&"
export * from "./FeedCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MegasredaContentEventsHeaderInfo: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Megasreda/Content/Events/Header/Info.vue').default,BaseImage: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Image/Image.vue').default,CoreIcon: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Core/Icon/Icon.vue').default,BaseShareButton: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Share/Button.vue').default,BaseShareTelegram: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Share/Telegram.vue').default,BaseShareWhatsapp: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Share/Whatsapp.vue').default,UiFeedCard: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Ui/Feed/Card.vue').default})
